import logo from './images/sektion331.png';
import './App.css';
import Countdown from './Components/Countdown.js';
//import React, { useState, useRef, useEffect } from 'react'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width='60%' alt='sektion 331' />
        <Countdown />
      </header>
    </div>
  );
}

export default App;
