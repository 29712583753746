import React, { useState, useRef, useEffect } from 'react'

const Countdown = () => {

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    //const [timer, setTimer] = useState('2023-04-03T15:00:00.000');
    const [timer, setTimer] = useState('00:00:00');

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24)) % 7;
        const weeks = Math.floor(total / (1000 * 7 * 24 * 60 * 60));;
        return {
            total, weeks, days, hours, minutes, seconds
        };
    }


    const startTimer = (e) => {
        let { total, weeks, days, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            setTimer(
                (weeks) + ' veckor och '
                + (days) + ' dagar. (' 
                + (hours) + ' timmar '
                + (minutes) + ' minuter '
                + (seconds) + ' sekunder) '

            )
        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next	
        setTimer('');

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date('2023-04-02T16:00:00.000');

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 10);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);


    return (
        <div className="App">
            <h2>{timer} <br/>
                kvar till allsvenska premiären mot Degerfors IF. <br/>
                Vi ses på sektion 331.
            </h2>
        </div>
    )
}

export default Countdown;
